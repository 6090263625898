.tempoStationContainer{
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #eedd82;
}
.searchContainer{
    width: 80%;
    margin: 2rem  auto 2rem auto;
}